<template>
	<div class="animated fadeIn">
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<template v-for="(tabInfo, index) in tabs">
						<b-tab :key="index">
							<template slot="title">
								<router-link
									:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
									:to="{ name: tabInfo.name }"
									class="list-group-item btn text-left">
									{{ translate(tabInfo.translate_key) }}
								</router-link>
							</template>
							<template
								v-if="tabIndex === index"
								class="p-0">
								<router-view />
							</template>
						</b-tab>
					</template>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { PersonalBusinessVolume as PBV } from '@/translations';

export default {
	name: 'Pbv',
	messages: [PBV],
	data() {
		return {
			tabIndex: 0,
			tabs: [
				{
					name: 'PersonalBusinessVolume',
					translate_key: 'report',
				},
				{
					name: 'PersonalBusinessVolumeTop',
					translate_key: 'top',
				},
				{
					name: 'NewEnrollment',
					translate_key: 'new_enrollment',
				},
				{
					name: 'NewEnrollmentTop',
					translate_key: 'new_enrollment_top',
				},
			],
		};
	},
};
</script>
